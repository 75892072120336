<template>
  <el-row class="tac">
    <el-col :span="24">
      <div>
         <div :class="['logo',collapseds ? 'opening' : '']">
            <img src="../assets/logos.png">
            <h5 :class="collapseds ? 'opt' : ''">运营系统</h5>
          </div>
          <el-menu
              class="el-menu-vertical-demo"
              unique-opened
              background-color="rgb(50, 65, 87)"
              text-color="#fff"
              :collapse="collapseds"
              :default-active="$route.path"
              router
              active-text-color="#fff">
            <el-submenu 
              v-for="item in menu" 
              :index="item.id"
              :key="item.id"
              @open="handleOpen"
            >
              <template slot="title">
                <i :class="item.icon"></i>
                <span v-text="item.name"></span>
              </template>

              <el-menu-item-group class="over-hide" v-for="children in item.children" :key="children.component">
                <el-menu-item :index="'/' + children.component" v-text="children.name">
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
      </div>
    </el-col>
  </el-row>
</template>

<script>
// import {data} from '@/config/menu-config'
import {listmenuMyApi} from '@/api/sysmenu'
export default {
  name: "NavMenu",
  props:{
    collapseds:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      // activeRoute:'',
      menu1: [
        {
          "id": "100",
          "parentId": "0",
          "component": "mainxx",
          "name": "数据",
          "icon": "el-icon-share",
          "url": null,
          "status": 1,
          "createTime": "2020-12-25T11:39:27",
          "children": [
            {
              "id": "100101",
              "parentId": "100",
              "component": "company",
              "name": "公司",
              "icon": "",
              "url": null,
              "status": 1,
              "createTime": "2020-12-25T11:39:27",
              "children": null
            },
            {
              "id": "100102",
              "parentId": "100",
              "component": "busniessGroup",
              "name": "商务组",
              "icon": "",
              "url": null,
              "status": 1,
              "createTime": "2020-12-25T11:39:27",
              "children": null
            },
            {
              "id": "100103",
              "parentId": "100",
              "component": "busniess",
              "name": "商务",
              "icon": "",
              "url": null,
              "status": 1,
              "createTime": "2020-12-25T11:39:27",
              "children": null
            },
            {
              "id": "100104",
              "parentId": "100",
              "component": "customers",
              "name": "客户",
              "icon": "",
              "url": null,
              "status": 1,
              "createTime": "2020-12-25T11:39:27",
              "children": null
            },
            {
              "id": "100105",
              "parentId": "100",
              "component": "apps",
              "name": "应用",
              "icon": "",
              "url": null,
              "status": 1,
              "createTime": "2020-12-25T11:39:27",
              "children": null
            },
            {
              "id": "100106",
              "parentId": "100",
              "component": "accountCustomer",
              "name": "客户账户",
              "icon": "",
              "url": null,
              "status": 1,
              "createTime": "2020-12-25T11:39:27",
              "children": null
            }
          ]
        },
        {
          "id": "200",
          "parentId": "0",
          "component": "mainxx",
          "name": "配置",
          "icon": "el-icon-setting",
          "url": null,
          "status": 1,
          "createTime": "2020-12-25T11:39:27",
          "children": [
            {
              "id": "200201",
              "parentId": "200",
              "component": "ads",
              "name": "广告位",
              "icon": "",
              "url": null,
              "status": 1,
              "createTime": "2020-12-25T11:39:27",
              "children": null
            },
            {
              "id": "200202",
              "parentId": "200",
              "component": "pd",
              "name": "pd",
              "icon": "",
              "url": null,
              "status": 1,
              "createTime": "2020-12-25T11:39:27",
              "children": null
            },
            {
              "id": "200203",
              "parentId": "200",
              "component": "appProfit",
              "name": "app收益",
              "icon": "",
              "url": null,
              "status": 1,
              "createTime": "2020-12-25T11:39:27",
              "children": null
            }
          ]
        },
        {
          "id": "300",
          "parentId": "0",
          "component": "mainxx",
          "name": "系统",
          "icon": "el-icon-setting",
          "url": null,
          "status": 1,
          "createTime": "2020-12-25T11:39:27",
          "children": [
            {
              "id": "300301",
              "parentId": "300",
              "component": "role",
              "name": "角色",
              "icon": "",
              "url": null,
              "status": 1,
              "createTime": "2020-12-25T11:39:27",
              "children": null
            },
            {
              "id": "300302",
              "parentId": "300",
              "component": "user",
              "name": "用户",
              "icon": "",
              "url": null,
              "status": 1,
              "createTime": "2020-12-25T11:39:27",
              "children": null
            }
          ]
        }
      ],
      menu: [],
    }
  },
  created() {
    console.info("123456")
    this.getMenuList();
  },
  methods: {
    // handleOpen(key, keyPath) {
    //   console.log(key, keyPath)
    // },

    // handleClose(key, keyPath) {
    //   console.log(key, keyPath)
    // },
    handleOpen(key, keyPath) {
        console.log(key, keyPath);
    },
    getMenuList() {
      let roleId = localStorage.getItem("roleId");
      console.info("roleId"+roleId)
      listmenuMyApi({roleId: roleId}).then(response => {
        console.info(response)
        this.menu = response.body
        console.log(this.$route.path)
      })

    }

  }
}
</script>

<style scoped>

.over-hide{
  overflow: hidden;
}
.tac{
  height: calc(100vh);
  border:none;
}
.el-menu-vertical{
  border:none;
}

.logo {
 width: 200px;
 padding: 14px;
 display: flex;
 align-items: center;
 overflow: hidden;
 white-space: nowrap;
 text-overflow: ellipsis;
 line-height: 32px;
 box-sizing: border-box;
 transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.opening{
  width: 64px;
  overflow: hidden;
}
.logo img{
  width: 32px;
  height: 100%;
}
.logo h5{
  font-size: 16px;
  color: #fff;
  margin: 0;
  opacity: 1;
  font-weight: normal;
  margin-left: 20px;
}
.logo .opt{
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
>>> .el-menu{
  border-right:none;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
    height: 100vh;
  }
</style>
