<template>
  <div id="app">
    <el-container>
      <div class="side">
          <navmenu :collapseds="iscollapse"/>
      </div>
      <el-container>
        <el-header class="header">
          <vheader @watchCollapse="watchCollapse"/>
        </el-header>
        <el-main>
          <transition name="fade-transform" mode="out-in">
            <router-view v-loading="loading"/>
          </transition>
        </el-main>
<!--        <el-footer>Footer</el-footer>-->
      </el-container>
    </el-container>
  </div>
</template>

<script>
import NavMenu from '@/layout/NavMenu'
import Header from '@/layout/Header'

export default {
  name: 'mainxx',
  provide () {
    return {
      load: this.reload,
      closeLoad:this.closeLoad,
    }
  },
  data() {
    return {
      iscollapse:false,
      loading:null
    }
  },
  methods:{
    watchCollapse(data) {
      this.iscollapse = data
    },
    reload() {
      this.loading = true
    },
    closeLoad() {
      this.loading = false
    }
  },
  components: {
    'navmenu': NavMenu,
    'vheader': Header
  }
}
</script>

<style>
body{
  margin: 0;
  padding: 0;
}
.el-header{
  background-color:#242f42;
}
.el-header, .el-footer {
  background-color: #fff;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  background-color: rgb(50, 65, 87);
  color: #333;
  line-height: 200px;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  /*padding: 0;*/

}

.el-menu-item.is-active{
  background-color: rgb(32, 160, 255) !important;
  transform: scaleY(1);
  opacity: 1;
  transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.el-menu-item.is-active:hover{
  background-color: rgb(32, 160, 255) !important;
}

.opt{
  width: auto;
}
.side{
  background-color: rgb(50, 65, 87);
  /* height: 100vh; */
}

.el-menu-item:hover{
  background: rgb(40, 52, 70) !important;
}
.el-submenu__title:hover {
  background: rgb(40, 52, 70) !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.28s;
}
 
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
 
/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all .5s;
}
 
.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}
 
.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
 
/* breadcrumb transition */
.breadcrumb-enter-active,
.breadcrumb-leave-active {
  transition: all .5s;
}
 
.breadcrumb-enter,
.breadcrumb-leave-active {
  opacity: 0;
  transform: translateX(20px);
}
 
.breadcrumb-move {
  transition: all .5s;
}
 
.breadcrumb-leave-active {
  position: absolute;
}

</style>
