<template>
      <div>
        <el-row type="flex"   justify="space-between">
          <el-col :span="0.5">
          <div class="grid-content bg-purple" @click="tabCollapse"> 
            <i :class="[!isCollapse ? 'el-icon-s-fold' : 'el-icon-s-unfold','size']"></i>
          </div>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <span class="uspace">{{uname}}</span>
              <el-button round  @click="quit">退出登录</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
</template>

<script>
import { getData, removeToken} from '@/utils/cache'
export default {
  name: "Header",
  data(){

    var uname = getData("uname");

    return{
        uname:uname,
        isCollapse:false
    }
  },
  methods:{

    quit(){
      removeToken();
      this.$router.push({path:'/' });
    },
    tabCollapse() {
      this.isCollapse = !this.isCollapse
      this.$emit('watchCollapse',this.isCollapse)
    }
  }
}

</script>

<style scoped>

.bg-purple {

  /*background-color: #0d66dc;*/
}
.uspace{
  margin-right: 20px;
}

.size{
  font-size: 24px;
  cursor: pointer;
}

</style>
